.feedbacks-table-card-container-new {
  padding: 15px 20px 20px 20px;

  .feedbacks-table-card-inner-new {
    position: relative;
    // margin-bottom: 1.5rem;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;

    .feedbacks-table-card-header-new {
      display: flex;
      justify-content: space-between;
      border-radius: 5px 5px 0 0;
      background: none;
      padding: 1rem 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;

      .feedbacks-table-card-title-left {
        display: inline-flex;
        align-items: center;
        justify-content: start;
        gap: 12px;

        .feedbacks-table-card-title-left-img {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          font-size: 30px;
          color: #fff;
          background: linear-gradient(90deg, #736bd0 0%, #9892dc 100%);
          box-shadow: 0px 4px 15px 0px #7c75d347;
          border-radius: 20px;

          img {
            height: 18px;
            width: 18px;
          }
        }

        .feedbacks-table-card-title-left-text {
          font-size: 20px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .feedbacks-table-card-title-right {
        font-size: 32px;
        font-weight: 700;
        line-height: 24.2px;
        color: #df000d;
      }
    }

    .feedbacks-table-card-body-new {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      margin: 0;
      position: relative;
      padding: 25px;
      // padding: 15px 15px 20px 15px;
      font-size: 14px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);

      .feedback-table-card-top {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;

        .feedback-table-card-top-left {
          display: flex;
          align-items: center;
          font-weight: 500;

          .feedback-table-card-top-left-onboarding {
            margin-right: 20px;
            font-size: 16px;
          }

          .filter {
            &__control {
              border: 1px solid #eaedf1;
              border-radius: 40px;
              padding: 0 9px;
              font-weight: 500;
              background-color: #eae8f0;
            }

            &__control--is-focused {
              box-shadow: none;
              border: 1px solid #eaedf1;
            }

            &__menu {
              color: #0d0c22;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              user-select: none;
              cursor: pointer;
              border-radius: 0;
              margin-top: 0;
              max-height: 200px;
              border-radius: 5px;
            }

            &__option {
              font-size: 14px;
              cursor: pointer;
              padding: 6px;

              &--is-focused {
                color: white;
                background-color: #6259ca !important;
              }

              &--is-selected {
                color: white;
                background-color: #6259ca !important;
              }
            }

            &__option:hover {
              color: white;
              background-color: #6259ca;
            }

            &__single-value {
              font-size: 14px;
            }

            &__control {
              cursor: pointer;
            }

            &__indicator-separator {
              display: none;
            }
          }
        }

        .feedback-table-card-top-right {
          display: inline-flex;
          gap: 14px;

          .feedback-table-search-input-field {
            display: flex;
            border-radius: 28px;
            padding: 8px 13px;
            float: right;
            margin-bottom: 10px;
            border: 1px solid #eaedf1 !important;
            font-size: 14px;
            line-height: 18px;
          }

          .feedback-table-search-input-field:focus {
            outline: none;
          }

          .filter {
            &__control {
              border: 1px solid #eaedf1;
              border-radius: 40px;
              padding: 0 9px;
              font-weight: 500;
              background-color: #eae8f0;
            }

            &__control--is-focused {
              box-shadow: none;
              border: 1px solid #eaedf1;
            }

            &__menu {
              color: #0d0c22;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              user-select: none;
              cursor: pointer;
              border-radius: 0;
              margin-top: -10px;
              max-height: 200px;
              border-radius: 5px;
            }

            &__option {
              font-size: 14px;
              cursor: pointer;
              padding: 6px;

              &--is-focused {
                color: white;
                background-color: #6259ca !important;
              }

              &--is-selected {
                color: white;
                background-color: #6259ca !important;
              }
            }

            &__option:hover {
              color: white;
              background-color: #6259ca;
            }

            &__single-value {
              font-size: 14px;
            }

            &__control {
              cursor: pointer;
            }

            &__indicator-separator {
              display: none;
            }
          }
        }
      }

      @media (max-width: 719px) {
        .feedback-table-card-top {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          margin-bottom: 10px;

          .feedback-table-card-top-left {
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-bottom: 10px;

            .feedback-table-card-top-left-show {
              margin-right: 5px;
            }

            .feedback-table-card-top-left-entries {
              margin-left: 5px;
            }

            .filter {
              &__control {
                border: 1px solid #eaedf1;
              }

              &__control--is-focused {
                box-shadow: none;
                border: 1px solid #eaedf1;
              }

              &__menu {
                color: #0d0c22;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                user-select: none;
                cursor: pointer;
                border-radius: 0;
                margin-top: 0;
                max-height: 200px;
              }

              &__option {
                font-size: 14px;
                cursor: pointer;
                padding: 6px;

                &--is-focused {
                  color: white;
                  background-color: #6259ca !important;
                }

                &--is-selected {
                  color: white;
                  background-color: #6259ca !important;
                }
              }

              &__option:hover {
                color: white;
                background-color: #6259ca;
              }

              &__single-value {
                font-size: 14px;
              }

              &__control {
                cursor: pointer;
              }

              &__indicator-separator {
                display: none;
              }
            }
          }

          .feedback-table-search-input {
            display: flex;
            border-radius: 7px;
            padding: 0.5rem;
            float: right;
            margin-bottom: 10px;
            border: 1px solid #eaedf1 !important;
            font-size: 14px;
            line-height: 18px;
          }

          .feedback-table-search-input:focus {
            outline: none;
          }
        }
      }

      table {
        width: 100%;
        table-layout: fixed; /* This ensures that column widths are fixed */
      }

      .feedback-table-table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;

        .feedback-table-spinner-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 510px;
          border: 1px solid #eaedf1 !important;
          margin-bottom: 1rem;
          margin-top: -1rem;

          .feedback-table-spinner-loader {
            height: 35px;
            width: 35px;
          }

          .no-feedbacks-found-text {
            font-size: 14px;
            letter-spacing: 0em;
            color: #262626;
          }
        }

        .feedback-table-table {
          white-space: nowrap !important;
          border: 1px solid #eaedf1 !important;
          padding: 0.75rem;
          vertical-align: top;
          width: 100%;
          max-width: 100%;
          margin-bottom: 1rem;
          border-collapse: collapse !important;
          caption-side: bottom;

          .feedback-table-table-head {
            vertical-align: bottom;

            .feedback-table-table-head-row-new {
              border-width: 1px 0;
              border-top: 1px solid #eaedf1 !important;
              background: #eae8f0;

              .feedback-table-table-text-header-new {
                font-weight: 600 !important;
                transition: none !important;
                -webkit-transition: none !important;
                padding: 0.75rem;
                color: #000000;
                font-size: 12px;
                border: 1px solid #eaedf1;
                text-align: center;
                line-height: 25px;
              }

              .feedback-table-header-first {
                min-width: 1%;
                width: 18%;

                @media only screen and (max-width: 1800px) {
                  width: 15%;
                }
              }

              .feedback-table-header-second {
                min-width: 1%;
                width: 16%;

                @media only screen and (max-width: 1800px) {
                  width: 12%;
                }
              }

              .feedback-table-header-third {
                min-width: 1%;
                width: 27%;

                @media only screen and (max-width: 1800px) {
                  width: 20%;
                }
              }

              .feedback-table-header-fourth {
                min-width: 1%;
                width: 12%;

                @media only screen and (max-width: 1800px) {
                  width: 14%;
                }

                .feedback-table-header-sort-icon {
                  height: 18px;
                  width: 18px;
                  cursor: pointer;
                }

                .feedback-table-header-sort-icon-rotated {
                  height: 18px;
                  width: 18px;
                  cursor: pointer;
                  transform: rotate(180deg);
                }

                .feedback-table-header-no-sort-icon {
                  display: inline-flex;
                  width: 18px;
                  height: 1px;
                }
              }

              .feedback-table-header-fifth {
                min-width: 1%;
                width: fit-content;

                .feedback-table-header-sort-icon {
                  height: 18px;
                  width: 18px;
                  cursor: pointer;
                }

                .feedback-table-header-sort-icon-rotated {
                  height: 18px;
                  width: 18px;
                  cursor: pointer;
                  transform: rotate(180deg);
                }

                .feedback-table-header-no-sort-icon {
                  display: inline-flex;
                  width: 18px;
                  height: 1px;
                }
              }

              .feedback-table-header-sixth {
                min-width: 1%;
                width: fit-content;
              }

              .feedback-table-header-seventh {
                min-width: 1%;
                width: 120px;
              }
            }
          }

          .feedback-table-table-center {
            text-align: center;
          }

          .feedback-table-row {
            cursor: pointer;
          }

          .feedback-table-row:hover {
            background-color: #f7f6f9;
          }

          .feedback-table-table-data {
            position: relative;
            padding: 10px;
            border: 1px solid #eaedf1;

            .feedback-table-table-badge {
              display: inline-block;
              padding: 1em 0.8em;
              font-size: 10px;
              line-height: 0;
              text-align: center;
              white-space: nowrap;
              vertical-align: baseline;
              border-radius: 15px;
              font-weight: 500;
              color: #fff;
              width: 70px;
            }

            .feedback-table-bg-success {
              color: #37b25a !important;
              background-color: #e7f7f4 !important;
              text-transform: capitalize;
            }

            .feedback-table-bg-danger {
              color: #e72d38 !important;
              background-color: rgba(248, 204, 211, 0.5) !important;
              text-transform: capitalize;
            }
          }

          .feedback-table-table-data-name-img-new {
            display: flex;
            gap: 8px;
            border: 1px solid transparent;
            border-bottom: 1px solid #eaedf1;
            margin-bottom: -1px;
            align-items: center;

            .feedback-table-table-cart-img-new {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              min-width: 30px;
              border-radius: 40px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: 30px 30px;

              .hide-feedback-table-table-cart-dp {
                display: none;
              }

              .feedback-table-table-cart-dp-pic {
                height: 30px;
                width: 30px;
                border-radius: 40px;
                object-fit: cover;
              }

              img {
                max-width: 100%;
                vertical-align: middle;
                border-style: none;
                pointer-events: none;
              }

              .feedback-table-profile-thumb-shimmer {
                height: 30px;
                width: 30px;
                border-radius: 40px;
                margin-top: -8px;
              }
            }

            .feedback-table-table-name-container {
              .feedback-table-table-name-new {
                display: block;
                font-size: 10px;
                font-weight: 600;
                // margin-bottom: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 160px;

                @media only screen and (max-width: 1800px) {
                  width: 100px;
                }

                @media only screen and (max-width: 1300px) {
                  width: 77px;
                }
              }
            }
          }

          .feedback-table-table-data-screen-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
          }

          .feedback-table-table-data-feedback {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            font-weight: 600;
            font-size: 10px;

            .feedback-table-table-data-no-feedback {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .feedback-table-table-data-feedback-text {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .feedback-table-read-more-container {
              display: flex;
              justify-content: flex-end;

              .feedback-table-read-more-text {
                display: flex;
                width: fit-content;
                align-items: center;
                justify-content: flex-end;
                color: #6259ca;
                text-decoration: underline;
                font-weight: 400;
                margin-right: 10px;
                cursor: pointer;

                .feedback-table-read-more-icon {
                  height: 7px;
                  width: 5px;
                  margin-right: 3px;
                  margin-bottom: 1px;
                  cursor: pointer;
                }
              }
            }
          }

          .feedback-table-table-data-date {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
          }

          .feedback-table-table-data-time {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
          }
        }
      }

      .feedback-table-empty-bottom {
        height: 41px;
      }

      .feedback-table-card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .feedback-table-card-bottom-left {
          font-size: 12px;
          font-weight: 500;
        }
      }

      @media (max-width: 819px) {
        .feedback-table-card-bottom {
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-top: 10px;

          .feedback-table-card-bottom-left {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
