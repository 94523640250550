@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  font-family: "Roboto", sans-serif;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


// no scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffffffb4;
    border-radius: 10px;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
