.dashboard {
  display: flex;
  .sidebar_col {
    // height: 100vh;
    height: calc(100vh - 80px);
    max-width: 270px;
    width: 100%;
    background-color: #fff;

    .logo {
      // background: #fff;
      // border-bottom: 1px solid #eaedf1;
      // border-right: 1px solid #eaedf1;
      // color: #000;
      // font-size: 30px;
      // font-weight: 800;
      // height: 75px;
      // letter-spacing: 1px;
      // padding: 12px;
      // text-align: center;
    }

    .menus {
      height: calc(100vh - 80px);
      overflow-y: scroll;
      position: relative;
      z-index: 0;
      min-width: 250px;
      width: 100%;
      // background-color: red;

      /* For WebKit browsers (Chrome, Safari, etc.) */
      &::-webkit-scrollbar {
        width: 4px; /* Set the width of the scrollbar */
        border-radius: 5px; /* Set the border radius for the scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888; /* Set the color of the scrollbar thumb */
        border-radius: 5px; /* Set the border radius for the scrollbar thumb */
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Set the color of the scrollbar thumb on hover */
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1; /* Set the color of the scrollbar track */
        border-radius: 5px; /* Set the border radius for the scrollbar track */
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #ddd; /* Set the color of the scrollbar track on hover */
      }
    }
  }

  .content_col {
    background: #f2f3f9;
    // background: pink;
    height: 100vh !important;
    overflow-x: hidden;
    position: relative;
    width: 100%;

    // .topbar {
    //   background: #fff;
    //   border-bottom: 1px solid #eaedf1;
    //   height: 80px;
    //   padding: 0 56px;
    //   // position: fixed;
    //   // width: calc(100vw - 270px);
    //   position: sticky;
    //   top: 0;
    //   width: 100vw;
    //   z-index: 1;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;

    //   .left_side {
    //     .logo {
    //       display: flex;
    //       align-items: end;
    //       .title_logo {
    //         font-style: italic;
    //         font-size: 11px;
    //         font-weight: 500;
    //       }
    //     }
    //   }

    //   .right_side {
    //     display: flex;
    //     align-items: center;

    //     .user_section {
    //       span {
    //         margin-right: 40px;
    //         cursor: pointer;
    //         margin-left: 7px;
    //       }
    //       img {
    //         cursor: pointer;
    //       }
    //     }
    //     .logout_section {
    //       img {
    //         cursor: pointer;
    //       }
    //       span {
    //         font-size: 16px;
    //         font-weight: 400;
    //         cursor: pointer;
    //       }
    //     }
    //   }
    // }

    .content_div {
      display: flex;
      overflow: auto;
      .content_area {
        width: 100% !important;
        height: calc(100vh - 80px);
        overflow: auto;
      }
    }
  }
}
