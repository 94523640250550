.topbar {
  background: #fff;
  border-bottom: 1px solid #eaedf1;
  height: 80px;
  padding: 0 56px;
  // position: fixed;
  // width: calc(100vw - 270px);
  position: sticky;
  top: 0;
//   width: 100vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_side {
    .logo {
      display: flex;
      align-items: end;
      cursor: pointer;
      .title_logo {
        font-style: italic;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  .right_side {
    display: flex;
    align-items: center;

    .user_section {
      span {
        margin-right: 40px;
        // cursor: pointer;
        margin-left: 7px;
      }
      img {
        // cursor: pointer;
        height: 30px;
        width: 30px;
        object-fit: cover;
        margin-top: -1px;
      }
    }
    .logout_section {
      img {
        cursor: pointer;
        border-radius: 100%;
        height: 36px;
        width: 36px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        padding-left: 9px;
      }
    }
  }
}
