.logoutModal {
  transform: none;
  height: 100vh !important;
  //   background: red;
  display: flex !important;
  align-items: center;

  .modalBody {
    width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: 53px;
      margin-bottom: 37px;
      width: 60px;
    }
    .message {
      margin-bottom: 30px;
    }
  }

  .action_btns {
    width: 100%;
    padding: 0 36px;
    padding-bottom: 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .no_btn,
    .yes_btn {
      width: 140px;
      height: 40px;
      border-radius: 5px;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 600;
    }

    .yes_btn{
        background-color: #736BD0;
        color: white;
    }
    .no_btn{
        border: 1px solid #747474;
        background-color: white;
        color: #747474;
    }
  }
}
