.User-Details-Wrapper {
  background: #f1f1f1;
  .padding-wrapper {
    padding: 20px;
  }
  .Banner-img {
    width: 100%;
    height: 181px;
    padding: 20px;
    background-color: #eaeaea;
    .background-inner-wrapper {
      display: flex;
      justify-content: space-between !important;
      margin-top: 105px;
      .Active-wrapper {
        display: flex;
        padding: 6px 15px 6px 15px;
        align-items: center;
        border-radius: 50px;
        background: #fff;
        width: 85px;
        height: 26px;
        margin-top: 10px;
        .Active-green {
          //display: block;
          width: 10px;
          height: 10px;
          background-color: rgba(74, 198, 109, 1);
          border-radius: 50%;
        }
        .Inactive-red {
          //display: block;
          width: 10px;
          height: 10px;
          background-color: #ff5761;
          border-radius: 50%;
        }
        .active-Text {
          color: rgba(0, 0, 0, 1);
          margin-left: 5px;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: center;
          margin-top: 2px;
        }
      }
      .card-category-wrapper {
        padding: 2px 16px 2px 16px;
        border-radius: 50px;
        background: #fff;
        width: auto;
        margin-top: 10px;
        .card-Text {
          color: rgba(0, 0, 0, 1);
          margin-left: 5px;
          font-size: 12px;
          font-weight: 500;
          line-height: 25px;
          letter-spacing: 0px;
          text-align: center;
        }
      }
    }
  }

  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 8px 8px 8px 0 !important;
    margin: 0;
    position: relative;
    padding-bottom: 0px !important;
  }
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid transparent;
    background-clip: border-box;
    border-radius: 0px 0px 5px 5px;
  }
  .card-profile {
    padding-bottom: 0 !important;
  }

  .UpperProfile-Wrapper {
    display: block;
    .content-top-wrapper {
      padding-bottom: 30px;
      padding-top: 20px;
      display: flex !important;
      justify-content: space-between;
      gap: 20px;
      padding-right: 20px;
      .BloodGroup-wrapper {
        width: fit-content;

        display: flex;
        align-self: flex-end;
        float: right;
        // margin-right: 20px;
        border-radius: 50px;
        background: rgba(231, 45, 56, 1);
        color: rgba(255, 255, 255, 1);
        padding: 6px 17px;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: center;
        margin-left: 20px;
      }
      .add-right-margin {
        margin-right: 19px;
      }
      .Dark-detail-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 6px 0px 6px 20px;
        align-items: center;
        gap: 20px;
        border-radius: 50px;
        background: #262626;
        max-width: 313px;
        width: fit-content;
        // min-width:278px;
        height: 37px;
        margin-left: 10px;

        // position: relative;

        img {
          width: 18px;
          height: 18px;
          // cursor: pointer;
          // position: absolute;
          // right: 0;
          margin-right: 20px;
          cursor: pointer;
        }
      }
      .add-space {
        margin-left: 10px;
      }
    }

    .user-dp-shimmer-loader {
      margin-top: -4px;
    }

    .content-middle-wrapper {
      padding-top: 30px;
      .profile-image-custom {
        height: 140px;
        width: 140px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        object-fit: cover;
      }
      .isBorder {
        border: 2.5px solid #147bff;
      }
      .isBorderGrey {
        border: 2.5px solid #cbcbcb;
      }

      .verifiedIcon {
        position: absolute;
        top: 103px;
        left: 110px;
        width: 28px;
        height: 28px;
      }
      .Divider-wrapper {
        display: flex;
        justify-content: space-between;
        .borderLine {
          border-right: 1px solid rgba(0, 0, 0, 0.3);

          height: 160px;
        }
        .addBottom-margin {
          margin-bottom: 20px;
        }
        .addPadding {
          padding-left: 10px;
        }
        .user-wrap {
          margin-left: 30px;
          margin-right: 50px;
          max-width: 58%;
          .manager-span {
            line-height: 14px;
          }
          h4 {
            margin-bottom: 4px !important;
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2; /* Number of lines to show */
          }
          .designation {
            color: #686868;
            text-align: center;
            margin-top: 5px;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .designation-count {
            margin-left: 12px;
            margin-top: 5px;
            display: inline-flex;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 16.667px;
            border-radius: 50px;
            background: #efefef;
            color: #262626;

            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
            height: 15px;
            width: 20px;
          }
          .main_test {
            position: relative;
            max-width: fit-content;

            .company-desc-top {
              color: #686868;
              cursor: pointer;
              // text-align: center;
              margin-top: 5px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
            }
          }
          .location {
            color: #147bff;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 12px;
            span {
              cursor: pointer;
            }
          }
          .red-button {
            width: 116px;
            height: 32px;
            border-radius: 16px;
            background: #df000d;
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
          }

          .titleHeading {
            cursor: pointer;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
          }
          .designation-count-big {
            margin-left: 12px;
            cursor: pointer;

            border-radius: 50px;
            background: #efefef;

            display: flex;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            gap: 16.667px;
            color: #262626;
            margin-top: 2px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.28px;
            height: 22px;
            width: 32px;
          }
          .userTitleList {
            width: 100%;
            max-width: 800px;
            min-width: 425px;
            color: #878787;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            margin-top: 7px;
            word-wrap: break-word;
            text-wrap: wrap;
            margin-bottom: 20px;
          }
        }
      }
    }

    .border-top {
      border-top: 1px solid #eaedf1 !important;
    }

    .TabMenus-wrapper {
      display: flex;
      padding: 0px 16px 0 0px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 0px 0px 8px 8px;
      background: #fff;
      width: 100%;
      .tab-menu-heading {
        padding: 0;
        border: 0;
      }

      .nav {
        .nav-menu-wrapper {
          gap: 15px;

          cursor: pointer;
          width: 173px;
          height: 54px;
          text-align: center;
          padding-top: 18px;
        }

        .nav-menu-wrapper-active {
          gap: 15px;

          padding-top: 18px;
          border-bottom: 4px solid rgba(115, 107, 208, 1);
          text-align: center;
          cursor: pointer;
          width: 173px;
          height: 54px;
        }
        .reduce-border {
          margin-left: -12px !important;
        }
        .margin-left-addon {
          // margin-left: 15px;
        }

        .Nav-menu-Names {
          color: black;
          font-size: 15px;

          text-decoration: none;
          text-wrap: nowrap;

          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0px;
          text-align: center;
        }
      }
    }
  }

  .Tab-data-wrapper {
    padding: 20px 0px 0px 0px;
  }

  @media (max-width: 768px) {
    .borderLine {
      border: 0px solid !important;
    }
  }
  @media (max-width: 992px) {
    .borderLine {
      border: 0px solid !important;
    }
  }
  .text-wrapper {
    max-width: 375px;
    position: relative;
  }

  .text-wrapper-full {
    position: relative;
    width: 100%;
  }
  @media (max-width: 768px) {
    .text-wrapper {
      max-width: 600px;
    }
    .text-wrapper-full {
      width: 100% !important;
    }
  }

  .padding-top-about {
    padding-top: 20px;
  }
  .add-flex {
    display: flex;
  }

  @media (max-width: 768px) {
    .content-top-wrapper {
      .col-md-2 {
        flex: 0 0 auto;
        // width: 22%;
      }
    }

    .add-flex {
      display: block;
    }
    .add-space {
      margin-left: 20px !important;
      margin-top: 20px;
    }
  }

  @media (min-width: 992px) {
    .content-top-wrapper {
      .col-lg-2 {
        flex: 0 0 auto;
        // width: 20%;
      }
    }
  }
  @media (min-width: 1200px) {
    .content-top-wrapper {
      .col-lg-2 {
        flex: 0 0 auto;
        // width: 16%;
      }
    }
  }

  @media (max-width: 576px) {
    .add-flex {
      display: block;
    }
    .add-space {
      margin-left: 20px !important;
      margin-top: 20px;
    }
  }
}

.customPopover {
  position: absolute !important;
  top: 30px;
}
.customPopover2 {
  position: absolute !important;
}

.popoverArrowLeft {
  left: 5% !important;
  max-width: 400px !important;
}
