.award-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 34px 0 34px 0;
}

.pagination-title {
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    margin-left: 20px;
    /* 208.333% */
}

.award-table-pagination {
    float: right;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    font-size: 14px;
    user-select: none;
    margin-right: 20px;

    .award-table-page-item:hover {
        color: #6259ca;
        background-color: rgb(98 89 202 / 10%);
    }

    .award-table-page-item {
        cursor: pointer;
        background-color: #fff;
        color: #0D0C22;

        .award-table-page-link {
            position: relative;
            display: block;
            padding: 0.5rem 0.75rem;
            line-height: 1.25;
            border-top: 1px solid #eaedf1;
            border-bottom: 1px solid #eaedf1;
            border-right: 1px solid #eaedf1;
        }
    }

    .award-table-next {
        border-right: 1px solid #eaedf1;
        border-radius: 0 3px 3px 0;
        cursor: pointer;
        background-color: #fff;
        color: #0D0C22;
        border-top: 1px solid #eaedf1;
        border-bottom: 1px solid #eaedf1;
        border-right: 1px solid #eaedf1;

        .award-table-page-link {
            position: relative;
            display: block;
            padding: 0.5rem 0.75rem;
            line-height: 1.25;
        }
    }

    .award-table-next:hover {
        color: #6259ca;
        background-color: rgb(98 89 202 / 10%);
    }

    .award-table-prev {
        border-right: 1px solid #eaedf1;
        border-radius: 0 3px 3px 0;
        cursor: pointer;
        background-color: #fff;
        color: #0D0C22;
        border-top: 1px solid #eaedf1;
        border-bottom: 1px solid #eaedf1;
        border-left: 1px solid #eaedf1;

        .award-table-page-link {
            position: relative;
            display: block;
            padding: 0.5rem 0.75rem;
            line-height: 1.25;
        }
    }

    .award-table-prev:hover {
        color: #6259ca;
        background-color: rgb(98 89 202 / 10%);
    }

    .award-table-disabled {
        color: #d3d3d3;
        opacity: 0.8;
        cursor: default;
        background-color: #fff !important;
    }

    .award-table-disabled:hover {
        color: #d3d3d3;
        opacity: 0.8;
        cursor: default;
        background-color: #fff !important;
    }

    .award-table-active {
        color: #fff;
        background-color: #6259ca;
        border-color: #6259ca;
        margin-left: -1px;
    }

    .award-table-active:hover {
        color: #fff;
        background-color: #6259ca;
        border-color: #6259ca;
        margin-left: -1px;
    }
}