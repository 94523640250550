.login_page {
  background-image: url("../../assets/Images/login.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  //   justify-content: center;
  flex-direction: column;
  position: relative;

  .welcome {
    color: white;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 47px;
  }

  #elredLogo {
    margin-top: 50px;
    margin-bottom: 75px;
  }
}
