.Info-wrapper {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 20px;
  .divident-heading-wrapper {
    height: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    margin-left: -17px;
    margin-right: -16px;
    margin-bottom: 20px;
    .divident-heading {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      margin-left: 20px;
    }
  }

  .loginDetails-wrapper {
    .detail-heading {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      text-wrap: nowrap;
    }

      .user-details-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 131px;
  
        .user-details-spinner-loader {
          height: 30px;
          width: 30px;
        }
      }

      .personal-details-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 172px;
  
        .personal-details-spinner-loader {
          height: 30px;
          width: 30px;
        }
    }

    .professional-details-spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 131px;

      .professional-details-spinner-loader {
        height: 30px;
        width: 30px;
      }
    }

    .details-data {
      color: #686868;
      margin-left: 5px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;

      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .caps {
      text-transform: capitalize;
    }
  }
}
