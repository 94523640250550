.companyDetails {
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  padding: 20px 20px;
  width: 48.5%;
  padding-top: 25px;
  position: relative;

  #icon_pinned_hidden {
    position: absolute;
    top: 9px;
    right: 11px;
  }

  .companyInfo {
    display: flex;
    position: relative;
  }

  .comDetails {
    display: flex;
    margin: 20px 0 20px 0;

    .cName-designation-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 23vw;
    }
  }

  .dateContainerCompany {
    display: flex;
  }

  .companyName {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // cursor: pointer;
  }

  .cName {
    color: var(--G3, #686868);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 3px;
    // cursor: pointer;
  }

  .cName-company-ellisis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 34vw;
  }

  .unverified-company-ellisis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 36vw;
  }

  .cName-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9vw;
  }

  .cName-designation-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 23vw;
  }
}
