.tooltip {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;

    .tooltip-arrow {
        margin-left: -2px;
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
    }

    .tooltip-arrow-top {
        left: 50%;
    }

    .tooltip-arrow-bottom {
        left: 50%;
    }

    .tooltip-arrow-start {
        right: -11%;
        top: 8px;
    }

    .tooltip-arrow-end {
        left: -9%;
        top: 8px;
    }

    .tooltip-inner {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 3px;
    }
}