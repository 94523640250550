.profile-tab-skils-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 5px;
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  // margin: 20px 20px 0 20px;

  .profile-tab-skills-card-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 1);
  }

  .profile-tab-skills-card-container {
    padding: 20px;
    display: flex;
    gap: 19px;
  }
}

.profile-tab-accordion-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 5px;
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  margin-top: 20px;

  .profile-tab-accordion-card-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    padding: 17px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 1);
  }

  .profile-tab-accordion-card-container {
    padding: 34px 20px 20px 20px;

    .no-super-skills-gamification {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 332px;
      font-size: 14px;
      font-weight: 400;
      color: #262626;
    }

    .profile-tab-accordion-card-loader {
      height: 332px;
      display: flex;
      align-items: center;
      justify-content: center;

      .profile-tab-accordion-card-spinner-loader {
        margin-top: -20px;
        height: 30px;
        width: 30px;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }
}
